import { render, staticRenderFns } from "./PigeonHouse.vue?vue&type=template&id=798a20b6&scoped=true"
import script from "./PigeonHouse.vue?vue&type=script&lang=js"
export * from "./PigeonHouse.vue?vue&type=script&lang=js"
import style0 from "./PigeonHouse.vue?vue&type=style&index=0&id=798a20b6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "798a20b6",
  null
  
)

export default component.exports