<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="16">
          <el-form :inline="true" :model="formData">
            <el-form-item>
              <el-input v-model="formData.name" :placeholder="$t('pigeonHouse.msg.inputSearchPrompt')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitSearch">{{$t('competition.btns.search')}}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" type="flex" :align="'end'" justify="center">
          <template>
            <el-button type="primary" @click="clickHouseAddBtn">{{$t('pigeonHouse.createPigeonHouse')}}</el-button>
            <el-upload class="inline-block" accept=".xls,.xlsx" :show-file-list="false" action="#" :auto-upload="false" :on-change="handleImportHouse">
              <el-button type="primary">{{$t('pigeonHouse.btns.importFile')}}</el-button>
            </el-upload>
          </template>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16">
          <el-form :inline="true" :model="formData">
            <el-form-item>
              <el-input v-model="formData.imei" :placeholder="$t('pigeonHouse.msg.inputPrompt')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchImei">{{$t('competition.btns.search')}}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <template>{{onPigeonHouseInfo()}}</template>
      </el-row>
      <el-table ref="filterTable" :data="showDataList" default-expand-all stripe :border="true" style="width: 100%" @expand-change="onExpandChange">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table ref="filterTable" :data="scope.row.subData" v-if="scope.row.subData.length !== 0" stripe :border="true" :header-cell-style="tableHeaderCellStyle" :cell-style="tableSubCellStyle" style="width: 100%">
              <el-table-column type="index" :label="$t('common.sn')" width="80"></el-table-column>
              <el-table-column prop="ringNo" :label="$t('pigeonHouse.pigeonNo')" width="120"></el-table-column>
              <el-table-column prop="electronicRingNo" :label="$t('pigeonHouse.pigeonElectronicNo')" width="120"></el-table-column>
              <el-table-column prop="deviceImei" :label="$t('pigeonHouse.imei')" width="120"></el-table-column>
              <el-table-column prop="qualification" :label="$t('pigeonHouse.pigeonRemark')" width="120"></el-table-column>
              <el-table-column :label="$t('device.positioningTime')" width="120">
                <template slot-scope="scope">{{convertDateToString(scope.row.gpsTime)}}</template>
              </el-table-column>
              <el-table-column :label="$t('device.lastLongitude')" width="120">
              <template slot-scope="scope">{{scope.row.lon ? scope.row.lon : ''}}</template>
              </el-table-column>
              <el-table-column :label="$t('device.lastLatitude')" width="120">
              <template slot-scope="scope">{{scope.row.lat ? scope.row.lat : ''}}</template>
              </el-table-column>
              <el-table-column :label="$t('common.operation')">
              <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="clickEditButton(scope.row)">{{$t('common.edit')}}</el-button>
              <el-button type="danger" size="mini" @click="clickDeleteButton(scope.row)">{{$t('common.delete')}}</el-button>
              </template>
              </el-table-column>
              </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="index" :label="$t('common.sn')" width="80"></el-table-column>
        <el-table-column :label="$t('pigeonHouse.pigeonHouseNo')" width="80">
          <template slot-scope="scope">{{pigeonRindNo(scope.row)}}</template>
        </el-table-column>
        <el-table-column prop="userName" :label="$t('pigeonHouse.pigeonHouseName')" width="120"></el-table-column>
        <el-table-column prop="mobile" :label="$t('pigeonHouse.pigeonHousePhone')" width="120"></el-table-column>
        <el-table-column prop="ringCount" :label="$t('pigeonHouse.pigeonRingCount')" width="80">
          <!-- <template slot-scope="scope">{{scope.row.subData.length}}</template> -->
        </el-table-column>
        <!-- <el-table-column prop="ringNo" :label="$t('pigeonHouse.pigeonNo')" width="120"></el-table-column>
        <el-table-column prop="deviceImei" :label="$t('pigeonHouse.imei')" width="120"></el-table-column>
        <el-table-column :label="$t('device.positioningTime')" width="120">
          <template slot-scope="scope">{{convertDateToString(scope.row.gpsTime)}}</template>
        </el-table-column>
        <el-table-column :label="$t('device.lastLongitude')" width="120">
          <template slot-scope="scope">{{scope.row.lon ? scope.row.lon : ''}}</template>
        </el-table-column>
        <el-table-column :label="$t('device.lastLatitude')" width="120">
          <template slot-scope="scope">{{scope.row.lat ? scope.row.lat : ''}}</template>
        </el-table-column> -->
        <el-table-column :label="$t('common.operation')">
          <template slot-scope="scope">
            <!-- <el-button type="primary" @click="clickEditButton(scope.row)">{{$t('common.edit')}}</el-button> -->
            <el-button type="primary" @click="clickAddBtn(scope.row)">{{$t('pigeonHouse.addPigeonNo')}}</el-button>
            <!-- <el-upload class="margin-change" accept=".xls,.xlsx" :show-file-list="false" action="#" :auto-upload="false" :on-change="(file, fileList) => {handleImportFile(file, fileList, scope.row)}">
              <el-button type="primary">{{$t('pigeonHouse.btns.importFile')}}</el-button>
            </el-upload> -->
            <el-button type="danger" @click="clickHouseDelBtn(scope.row)">{{$t('common.delete')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.totalData">
      </el-pagination>
    </div>
    <!-- <el-dialog :title="$t('pigeonHouse.addPigeonNo')" :visible.sync="dialogVisible">
      <p>{{$t('pigeonHouse.pigeonNo')}}</p>
      <el-input type="text" v-model="dialogInputValue" :placeholder="$t('pigeonHouse.msg.inputPigeonNoPrompt')"></el-input>
      <p>{{$t('pigeonHouse.imei')}}</p>
      <el-input type="text" v-model="dialogInputImei" :placeholder="$t('device.msg.inputImei')"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="clickDialogConfirmButton">{{$t('common.confirm')}}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('pigeonHouse.editPigeonNo')" :visible.sync="imeiDialogVisible">
      <p>{{$t('pigeonHouse.imei')}}</p>
      <el-input type="text" v-model="dialogInputImei" :placeholder="$t('device.msg.inputImei')"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="imeiDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="clickImeiDialogConfirmButton">{{$t('common.confirm')}}</el-button>
      </div>
    </el-dialog> -->
    <el-dialog :title="$t(ringForm.title)" :visible.sync="ringDialogVisible">
        <el-form ref="form" :model="ringForm" label-width="120px">
        <el-form-item v-if="ringForm.ringId === -1" :label="$t('pigeonHouse.pigeonNo')">
          <el-input v-model="ringForm.ringNo" :placeholder="$t('pigeonHouse.msg.inputPigeonNoPrompt')"></el-input>
        </el-form-item>
        <el-form-item v-if="ringForm.ringId === -1" :label="$t('pigeonHouse.pigeonElectronicNo')">
          <el-input v-model="ringForm.electronicNo" :placeholder="$t('pigeonHouse.msg.inputElectronicNoPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonHouse.imei')">
          <el-input ref="imeiInput" v-model="ringForm.imei" @input="onInputImei" :placeholder="$t('device.msg.inputImei')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonHouse.pigeonRemark')">
          <el-input v-model="ringForm.remark" :placeholder="$t('pigeonHouse.msg.inputRemarkPrompt')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="clickRingDialogConfirmBtn">{{$t("common.confirm")}}</el-button>
          <el-button @click="ringDialogVisible = false">{{$t("common.cancel")}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="$t(houseForm.title)" :visible.sync="houseDialogVisible">
        <el-form ref="form" :model="houseForm" label-width="120px">
        <el-form-item :label="$t('pigeonHouse.pigeonHouseNo')">
          <el-input v-model="houseForm.houseNo" :placeholder="$t('pigeonHouse.msg.inputNoPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonHouse.pigeonHouseName')">
          <el-input v-model="houseForm.name" :placeholder="$t('pigeonHouse.msg.inputNamePrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonHouse.pigeonHousePhone')">
          <el-input v-model="houseForm.phone" :placeholder="$t('pigeonHouse.msg.inputPhonePrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonGuild.pigeonGuildPassword')">
          <el-input v-model="houseForm.password" :placeholder="$t('pigeonHouse.msg.inputPasswordPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonGuild.passwordConfirm')">
          <el-input v-model="houseForm.passwordConfirm" :placeholder="$t('pigeonGuild.msg.hintPasswordConfirm')"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('pigeonHouse.pigeonHouseContact')">
          <el-input v-model="houseForm.contact" :placeholder="$t('pigeonHouse.msg.inputContactPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonHouse.pigeonHouseTel')">
          <el-input v-model="houseForm.tel" :placeholder="$t('pigeonHouse.msg.inputTelPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonHouse.pigeonHouseAddress')">
          <el-input v-model="houseForm.address" :placeholder="$t('pigeonHouse.msg.inputAddressPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonGuild.remark')">
          <el-input v-model="houseForm.remark" :placeholder="$t('pigeonGuild.msg.inputRemarkPrompt')"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="clickHouseDialogConfirmBtn">{{$t("common.confirm")}}</el-button>
          <el-button @click="houseDialogVisible = false">{{$t("common.cancel")}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {queryRaceDetails, insertRaceDetails, updateRaceDetails, deleteRaceDetails, registerAgent, queryRolesList, deleteAgent} from '@/api/requestUtils'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  data () {
    return {
      formData: {
        name: '',
        imei: ''
      },
      houseForm: {
        title: "",
        houseId: -1,
        houseNo: '',
        name: "",
        phone: '',
        password: "",
        passwordConfirm: "",
        contact: "",
        tel: "",
        address: "",
        remark: ""
      },
      ringForm: {
        title: "",
        ringId: -1,
        ringNo: '',
        electronicNo: "",
        imei: '',
        remark: ""
      },
      guildId: 0,
      guildName: '',
      houseId: 0,
      totalRingCount: 0,
      dataList: [],
      showDataList: [],
      subDataList: {},
      importHouseList: [],
      importRingList: {},
      pigeonHouseInfo: '',
      pagination: {
        pageSize: 10,
        currentPage: 1,
        totalData: 0
      },
      dialogVisible: false,
      imeiDialogVisible: false,
      ringDialogVisible: false,
      houseDialogVisible: false,
      isImporting: false,
      editObj: {},
      dialogInputValue: '',
      dialogInputImei: ''
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
  },
  mounted () {
    const guildId = sessionStorage.getItem('guildId')
    const guildName = sessionStorage.getItem('guildName')
    if (guildId) {
      this.guildId = guildId
      this.onQueryPigeonHouseList()
    }
    if (guildName) {
      this.guildName = guildName
    }
  },
  methods: {
    tableHeaderCellStyle(){
      return {'background-color': '#2B85F7', color: '#fff'}
    },
    tableSubCellStyle(){
      return {'background-color': '#F7F7F7'}
    },
    pigeonRindNo(data){
      return data.loginName.substring(this.guildName.length, data.loginName.length)
    },
    onPigeonHouseInfo(){
      let houseCount = this.dataList.length
      return this.$t("pigeonHouse.pigeonHouseCount") + ':' + houseCount + ',' + this.$t("pigeonHouse.pigeonRingCount") + ':' + this.totalRingCount
    },
    onSubmitSearch () {
      let name = this.formData.name.trim()
      if (name && name.length) {
        this.showDataList = []
        this.dataList.forEach(item => {
          if (item.loginName.substring(this.guildName.length, item.loginName.length).includes(this.formData.name) || item.userName.includes(this.formData.name)) {
            this.showDataList.push(item)
          }
        })
      } else {
        this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
      }
    },
    onSearchImei () {
      let imei = this.formData.imei.trim()
      if (imei && imei.length) {
        this.showDataList = []
      } else {
        this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
      }
      this.dataList.forEach(item => {
          let objList = this.subDataList[item.userId]
          if (imei && imei.trim()) {
            item.subData = []
            objList.forEach(subItem => {
              if (subItem.ringNo === this.formData.imei || subItem.deviceImei === this.formData.imei) {
                item.subData.push(subItem)
                this.showDataList.push(item)
              }
            })
          } else {
              item.subData = objList
          }
      })
    },
    convertDateToString (date) {
      if (date) {
        return moment(date).format("yyyy-MM-DD HH:mm:ss")
      } else {
        return ''
      }
    },
    handleSizeChange (pageSize) { // 分页:单页最大数量变化时的处理
      this.pagination.currentPage = 1
      this.pagination.pageSize = pageSize
      this.showDataList = this.dataList.slice(0, pageSize)
    },
    handleCurrentChange (currentPage) { // 分页:当前页数变化时的处理
      this.pagination.currentPage = currentPage
      this.showDataList = this.dataList.slice((currentPage - 1) * this.pagination.pageSize, currentPage * this.pagination.pageSize)
    },
    // 展开事件----动态获取内嵌表数据
    onExpandChange(row, expandedRows) {
      // 该处是用于判断是展开还是收起行，只有展开的时候做请求，避免多次请求！
      // 展开的时候expandedRows有值，收起的时候为空.
      if (expandedRows.length > 0 && (!this.subDataList[row.userId] || this.subDataList[row.userId].length === 0)) {
        this.onQueryRaceDetailsList(row.userId)
      }
    },
    onInputImei () {
      let imei = this.ringForm.imei
      if (this.ringForm.ringId !== -1 && imei && imei.trim() && (imei.substring(imei.length - 1, imei.length) === ' ' || imei.trim().length == 15)) {
          this.clickRingDialogConfirmBtn()
      }
    },
    clickEditButton (data) {
      // this.editObj = data
      // this.dialogInputImei = data.deviceImei
      // this.imeiDialogVisible = true
      this.houseId = data.houseId
      this.ringForm = {
        title: (this.$t('pigeonHouse.editPigeonNo') + '(' + this.$t('pigeonHouse.pigeonNo') + ':' + data.ringNo  + ')'),
        ringId: data.id,
        ringNo: data.ringNo,
        electronicNo: data.electronicRingNo,
        imei: data.deviceImei,
        remark: data.qualification ? (data.qualification === 1 ? '1' : '0') : '0'
      }
      this.ringDialogVisible = true
      this.$nextTick(() => {
        this.$refs.imeiInput.focus()
      })
    },
    clickAddBtn (data) {
      this.houseId = data.userId
      // this.dialogInputImei = ''
      // this.dialogVisible = true
      this.ringForm = {
        title: this.$t('pigeonHouse.addPigeonNo'),
        ringId: -1,
        ringNo: '',
        electronicNo: "",
        imei: '',
        remark: ""
      }
      this.ringDialogVisible = true
    },
    clickRingDialogConfirmBtn (){
      if(!this.ringDialogVisible) {
        return
      }
      let ringId = this.ringForm.ringId
      let ringNo = this.ringForm.ringNo.trim()
      let electronicNo = this.ringForm.electronicNo.trim()
      let imei = this.ringForm.imei.trim()
      let remark = this.ringForm.remark
      if (ringId !== -1 && !ringNo) {
        return this.$message.error(this.$t('pigeonHouse.msg.inputPigeonNoPrompt'))
      }
      if (!electronicNo) {
        return this.$message.error(this.$t('pigeonHouse.msg.inputElectronicNoPrompt'))
      }
      remark = remark ? ((remark === '1') ? 1 : 0) : 0
      this.ringDialogVisible = false
      if(ringId === -1) {
          this.onInsertRaceDetails(this.houseId, [{
          pigeonHouseId: this.houseId,
          ringNo: ringNo,
          deviceImei: imei,
          electronicRingNo: electronicNo,
          qualification: remark
        }])
      } else {
          this.onUpdateRaceDetails(ringId, ringNo, imei, electronicNo, remark)
      }
    },
    clickHouseAddBtn () {
      this.houseForm = {
        title: this.$t('pigeonHouse.createPigeonHouse'),
        houseId: -1,
        houseNo: '',
        name: "",
        phone: '',
        password: "",
        passwordConfirm: "",
        contact: "",
        tel: "",
        address: "",
        remark: ""
      }
      this.houseDialogVisible = true
    },
    clickHousEditBtn (data) {
      this.houseForm = {
        title: this.$t('pigeonHouse.editPigeonHouse'),
        houseId: data.id,
        houseNo: '',
        name: "",
        phone: '',
        password: "",
        passwordConfirm: "",
        contact: "",
        tel: "",
        address: "",
        remark: ""
      }
      this.houseDialogVisible = true
    },
    handleImportHouse (file, fileList) {
      this.handleImportFile(file, fileList, null)
    },
    handleImportFile (file, fileList, objData) {
      if(this.isImporting) {
        return this.$message.error(this.$t('pigeonHouse.msg.importPrompt'))
      }
      fileList.splice(0, fileList.length)
      const reader = new FileReader()
      reader.onload = (e) =>{
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'binary' })
        const result = {}
        // const houseList = []
        workbook.SheetNames.forEach(sheetName =>{
          // houseList.push(sheetName)
          const rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
          result[sheetName] = rowObject
          })
        if (result["腳環"]) {
          this.importRingList = {}
          result["腳環"].forEach((item, index) => {
            let ringList = []
            if(this.importRingList[this.guildName + item["會員"]]) {
              ringList = this.importRingList[this.guildName + item["會員"]]
            } else {
              this.importRingList[this.guildName + item["會員"]] = ringList
            }
            ringList.push({
              ringNo: item["腳環"],
              electronicRingNo: item["電子環"],
              qualification: item["失格"] ? (item["失格"] === 1 ? 1 : 0) : 0,
              deviceImei: "",
            })
          })
        }
        if (result['會員']) {
          this.importHouseList = []
          result['會員'].forEach((item, index) => {
            this.importHouseList.push({
              loginName: this.guildName + item["會員"],
              password: item["電話後五碼"],
              roles: "105",
              parentId: this.guildId,
              userName: item["姓名"],
              mobile: item["電話後五碼"]
            })
          })
          if(this.importHouseList.length) {
            this.isImporting = true
            this.onInsertPigeonHouse(this.importHouseList[0])
          }
        }
        // houseList.forEach((item) => {
        //     let houseId = null
        //     if (objData) {
        //       houseId = objData.userId
        //     } else {
        //       for (let index = 0; index < this.dataList.length; index++) {
        //         let dataModel = this.dataList[index]
        //         if (dataModel.userName === item) {
        //           houseId = dataModel.userId
        //           break
        //         }
        //       }
        //     }
        //     if(houseId) {
        //       result[item].forEach((subItem) => {
        //         subItem['pigeonHouseId'] = houseId
        //         if (!subItem.deviceImei) {
        //           subItem['deviceImei'] = ''
        //         }
        //       })
        //       this.onInsertRaceDetails(houseId, result[item])
        //     }
        //  })
        // if(result['RingNo']){
        //   result['RingNo'].forEach((item, index) => {
        //       item['pigeonHouseId'] = objData.userId
        //       if (!item.deviceImei) {
        //         item['deviceImei'] = ''
        //       }
        //     })
        //   this.onInsertRaceDetails(objData.userId, result['RingNo'])
        // }
      }
      reader.readAsBinaryString(file.raw)
    },
    clickHouseDelBtn (data) {
      this.showConfirmDialog(this.$t('common.notice'), this.$t('pigeonHouse.msg.deleteHouseTip'), () => {
        this.onDeletePigeonHouse(data.userId)
      })
    },
    clickDeleteButton (data) {
      this.showConfirmDialog(this.$t('common.notice'), this.$t('pigeonHouse.msg.deleteTip'), () => {
        this.onDeleteRaceDetails(data.houseId, data.id)
      })
    },
    clickDialogConfirmButton () {
      if (!this.dialogInputValue || !this.dialogInputValue.trim()) {
        this.dialogInputValue = ''
        return this.$message.error(this.$t('pigeonHouse.msg.inputPigeonNoPrompt'))
      } 
      // else if (!this.dialogInputImei || !this.dialogInputImei.trim()) {
      //   this.dialogInputImei = ''
      //   return this.$message.error(this.$t('device.msg.inputImei'))
      // }
      this.dialogVisible = false
      this.onInsertRaceDetails(this.houseId,[{
        pigeonHouseId: this.houseId,
        ringNo: this.dialogInputValue.trim(),
        deviceImei: this.dialogInputImei.trim()
      }])
    },
    clickImeiDialogConfirmButton () {
      if (!this.dialogInputImei || !this.dialogInputImei.trim()) {
        this.dialogInputImei = ''
        return this.$message.error(this.$t('device.msg.inputImei'))
      }
      this.imeiDialogVisible = false
      if(this.dialogInputImei.trim() != this.editObj.deviceImei) {
        this.onUpdateRaceDetails(this.dialogInputImei.trim())
      }
    },
    clickHouseDialogConfirmBtn () {
      let houseNo = this.houseForm.houseNo.trim()
      let name = this.houseForm.name.trim()
      let phone = this.houseForm.phone.trim()
      let password = this.houseForm.password.trim()
      let confirmPassword = this.houseForm.passwordConfirm.trim()
      if (!houseNo) {
        return this.$message.error(this.$t('pigeonHouse.msg.inputNoPrompt'))
      }
      if (!name) {
        return this.$message.error(this.$t('pigeonHouse.msg.inputNamePrompt'))
      }
      if (!phone || phone.length != 5) {
        return this.$message.error(this.$t('pigeonHouse.msg.inputPhonePrompt'))
      }
      if (password && password.length < 4) {
        return this.$message.error(this.$t('pigeonHouse.msg.inputPasswordPrompt'))
      }
      if (confirmPassword !== password) {
        return this.$message.error(this.$t('pigeonGuild.msg.hintPasswordConfirm'))
      }
      if(password.length === 0) {
          password = phone
      }
      this.houseDialogVisible = false
      if(this.houseForm.houseId === -1) {
          this.onInsertPigeonHouse({
            loginName: this.guildName + houseNo,
            password: password,
            roles: '105',
            parentId: this.guildId,
            userName: name,
            mobile: phone
        })
      } else {
          this.onUpdatePigeonHouse()
      }
    },
    showConfirmDialog (title, content, confirmCallback) {
      this.$confirm(content, title, {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warnning'
      }).then(confirmCallback).catch(() => {})
    },
    onImportRingList () {
      if(this.isImporting) {
        for (let index = 0; index < this.dataList.length; index++) {
          let item = this.dataList[index];
          let ringList = this.importRingList[item.loginName]
          if (ringList) {
            ringList.forEach((subItem, index) => {
              subItem['pigeonHouseId'] = item.userId
            })
            this.importRingList[item.loginName] = null
            this.onInsertRaceDetails(item.userId, ringList)
            return
          }
        }
        this.isImporting = false
        this.importRingList = {}
      }
    },
    onQueryRaceDetailsList (houseId) {
      queryRaceDetails(houseId, this.pagination.currentPage, this.pagination.pageSize).then(res => {
        if (res.data.code === 0 && res.data.data) {
          if (res.data.data[0]) {
            let objList = this.subDataList[houseId]
            objList.splice(0, objList.length)
            res.data.data[0].forEach(item => {
              item.houseId = houseId
              if(!item.deviceImei) {
                item.deviceImei = ''
              }
              item.index = objList.length + 1
              objList.push(item)
            })
            for (let index = 0; index < this.dataList.length; index++) {
                let item = this.dataList[index]
                if (houseId === item.userId) {
                  // this.totalRingCount += objList.length - item.ringCount
                  item.ringCount = objList.length
                  item.subData = []
                  objList.forEach((subItem) => {
                    if (this.formData.imei && this.formData.imei.trim()) {
                      if (
                        subItem.ringNo === this.formData.imei ||
                        subItem.ringdeviceImeiNo === this.formData.imei
                      ) {
                        item.subData.push(subItem);
                      }
                    } else {
                      item.subData.push(subItem);
                    }
                  });
                  break;
                }
              }
           }
          }
        }).catch(e => {})
    },
    onInsertRaceDetails (houseId, ringNoList) {
      insertRaceDetails(ringNoList).then(res => {
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.createSuccess'))
          this.totalRingCount += 1
          this.onQueryRaceDetailsList(houseId)
        } else {
          this.$message.error(this.$t('common.createFailed'))
        }
        this.onImportRingList()
      }).catch(e => {})
    },
    onUpdateRaceDetails (ringId, ringNo, deviceImei, electronicNo, remark) {
      let houseId = this.houseId
      updateRaceDetails(ringId, ringNo, deviceImei, electronicNo, remark).then(res => {
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.updateSuccess'))
          this.onQueryRaceDetailsList(houseId)
          // for (let index = 0; index < this.dataList.length; index++) {
          //     let item = this.dataList[index]
          //     console.log("updateRaceDetails:" + item.userId + ":" + houseId)
          //     if (houseId === item.userId) {
          //       console.log(item.subData)
          //       console.log('item.subData.length:'+item.subData.length)
          //       for (let j = 0; j < item.subData.length; j++) {
          //         let subItem = item.subData[j]
          //         console.log("AAAAAupdateRaceDetails:" + subItem.id + ":" + ringId)
          //         if (subItem.id === ringId) {
          //           item.subData.splice(j, 1)
          //           break
          //         }
          //       }
          //       console.log('end')
          //       break
          //     }
          // }
        } else {
          this.$message.error(this.$t('errorCode.deviceBound'))
        }
      }).catch(e => {})
    },
    onDeleteRaceDetails (houseId, ringId) {
      deleteRaceDetails([{id: ringId}]).then(res => {
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.deleteSuccess'))
          for (let index = 0; index < this.dataList.length; index++) {
              let item = this.dataList[index]
              if (houseId === item.userId) {
                for (let j = 0; j < item.subData.length; j++) {
                  let subItem = item.subData[j]
                  if (subItem.id === ringId) {
                    item.subData.splice(j, 1)
                    item.ringCount -= 1
                    this.totalRingCount -= 1
                    break
                  }
                }
                break
              }
          }
        } else {
          this.$message.error(this.$t('common.deleteFailed'))
        }
      }).catch(e => {})
    },
    onQueryPigeonHouseList () {
      queryRolesList(2, this.guildId).then(res => {
        if (res.data.code === 0 && res.data.data) {
          this.dataList = []
          this.showDataList = []
          if (res.data.data[0]) {
            this.totalRingCount = parseInt(res.data.strParameter)
            let num = 0
            res.data.data[0].forEach(item => {
              item.subData = [];
              if(this.subDataList[item.userId]){
                  this.subDataList[item.userId].forEach((subItem) => {
                    if (this.formData.imei && this.formData.imei.trim()) {
                      if (
                        subItem.ringNo === this.formData.imei ||
                        subItem.ringdeviceImeiNo === this.formData.imei
                      ) {
                        item.subData.push(subItem);
                      }
                    } else {
                      item.subData.push(subItem);
                    }
                  });
              } else {
                this.subDataList[item.userId] = []
                num += 1
                const self = this
                const userId = item.userId
                setTimeout(function () {
                  self.onQueryRaceDetailsList(userId)
                }, num * 300)
              }
              item.index = this.dataList.length + 1
              this.dataList.push(item)
              })
           }
           this.pagination.totalData = this.dataList.length
           this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
           if(!this.showDataList.length) {
             this.showDataList = this.dataList.slice(0, this.pagination.pageSize)
           }
           this.onImportRingList()
          } else {
            this.isImporting = false
          }
        }).catch(e => {})
    },
    onInsertPigeonHouse (dataObj) {
      registerAgent(dataObj).then((res) => {
          if (res.data.code === 0) {
            this.$message.success(this.$t("common.createSuccess"))
          } else if (res.data.code === 3010) {
            this.$message.error(this.$t("errorCode.accountExisted"))
          } else {
            this.$message.error(this.$t("common.createFailed"))
          }
          if(this.isImporting) {
            if(this.importHouseList.length) {
              this.importHouseList.splice(0, 1)
            }
            if(this.importHouseList.length) {
              this.onInsertPigeonHouse(this.importHouseList[0])
            } else {
              this.onQueryPigeonHouseList()
            }
          } else {
            this.onQueryPigeonHouseList()
          }
        })
        .catch((e) => {})
    },
    onUpdatePigeonHouse () {
      // let houseId = this.houseForm.houseId
      // let name = this.houseForm.name.trim()
      // let contact = this.houseForm.contact.trim()
      // let tel = this.houseForm.tel.trim()
      // let address = this.houseForm.address.trim()
      // let remark = this.houseForm.remark.trim()
      // updatePigeonHouse(houseId, name, contact, tel, address, remark).then(res => {
      //   if (res.data.code === 0) {
      //     this.$message.success(this.$t('common.updateSuccess'))
      //     for (let index = 0; index < this.dataList.length; index++) {
      //        let item = this.dataList[index]
      //         if (item.id === houseId) {
      //           item.houseName = name
      //           item.houseContact = contact
      //           item.houseTel = tel
      //           item.houseAddr = address
      //           item.houseRemark = remark
      //           break
      //         }
      //     }
      //   } else {
      //     this.$message.error(this.$t('common.updateFailed'))
      //   }
      // }).catch(e => {})
    },
    onDeletePigeonHouse (userId) {
      deleteAgent(userId).then(res => {
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.deleteSuccess'))
          for (let index = 0; index < this.dataList.length; index++) {
              let item = this.dataList[index]
              if (item.userId === userId) {
                this.dataList.splice(index, 1)
                this.totalRingCount -= item.ringCount
                this.pagination.totalData = this.dataList.length
                break
              }
          }
          this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
          if(!this.showDataList.length && this.pagination.currentPage > 1) {
            this.pagination.currentPage -= 1
            this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
          }
        } else {
          this.$message.error(this.$t('common.deleteFailed'))
        }
      }).catch(e => {})
    }
  }
}
</script>
<style lang="less" scoped>

.inline-block {
  display: inline-block;
  margin-left: 10px;
}
.margin-change {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

</style>
